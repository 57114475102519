import React from "react";

export default function NotLoggedIn() {
	return (
		<div>
			<h1>
				You must sign in to view this page!
			</h1>
		</div>
	);
}
